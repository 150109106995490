import { ProfileView } from "authory-api-types/dist/enums";
import styled from "styled-components";

export const V3ProfileListWrapper = styled.div<{ $viewType: ProfileView }>`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    
    > div {
        width: ${({ $viewType }) => $viewType === ProfileView.TwoColumnThumbnail ? "calc(50% - 48px);" : "100%"};
        margin-bottom: 80px;
    }
`;

export const V3ProfileListContainer = styled.div<{ $visible?: boolean }>`
    visibility: ${({ $visible }) => $visible ? "visible" : "hidden"};
    margin: 0 auto;
`

export const V3ProfileEditModeWrapper = styled.div<{ $showShadow: boolean }>`
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    outline: ${({ $showShadow }) => $showShadow ? "1.5px dashed rgba(110, 118, 135, 0.3)" : undefined};

    > * {
        pointer-events: none;
    }

    &:hover {
        outline: 1.5px dashed rgba(110, 118, 135, 0.3);
    }
`;