import { Collection } from "authory-api-types/dist/types";
import { V3ConfirmationModal } from "../V3ConfirmationModal";
import { V3ModalIconSegmentVariant } from "../ReusableStyledComponents/ModalIconSegment";
import { CollectionItemOrder } from "authory-api-types/dist/enums";
import { V3BodyGrey48 } from "../ReusableStyledComponents/V3BodyGrey48";
import { VerticalSpacing } from "../VerticalSpacing";
import { InlineLink } from "../ReusableStyledComponents/InlineLink";
import Link from "next/link";
import { APPLICATION_ROUTES } from "../../types/routes";

interface V3ModalCollectionOrderWarningProps {
    collection: Collection,
    setModalOpen: (open: boolean) => void,
    onSubmit: () => void,
}

export const V3ModalCollectionOrderWarning = ({ collection, setModalOpen, onSubmit }: V3ModalCollectionOrderWarningProps) => {
    const title = `This collection ${collection.itemOrder === CollectionItemOrder.Manual ? "has been ordered manually." : "is currently ordered by date."}`;

    const description = collection.itemOrder !== CollectionItemOrder.Manual ? <>
        <div style={{ display: "none" }}>
            <Link href={`${APPLICATION_ROUTES.COLLECTION_SORT.replace("[collectionId]", collection.canonicalSlug)}`}></Link>
        </div>
        <VerticalSpacing bottom={8}>
            <V3BodyGrey48>
                If you manually change the order of items in this collection, then all future items added to this collection will come after your manually ordered pieces, even if they have a more recent date. <InlineLink href="https://authory.com/help/en/articles/9015321-how-to-change-the-order-of-content-items#h_a38c87bd5b" target="_blank">Learn more</InlineLink>
            </V3BodyGrey48>
        </VerticalSpacing>
        <V3BodyGrey48>
            You can revert this collection to being ordered by date any time.
        </V3BodyGrey48>
    </> : <V3BodyGrey48>
        You have manually changed the order of items in this collection. If you switch to ordering by date, your manual ordering will be lost.
    </V3BodyGrey48>;

    return <V3ConfirmationModal
        onSubmit={onSubmit}
        setModalOpen={setModalOpen}
        modalOpen
        title={title}
        btnLabel={"Continue"}
        useIcon
        variant={V3ModalIconSegmentVariant.warning}
        description={description}
    />;
}