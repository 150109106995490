import React, { useContext, useEffect, useState } from "react";
import { ArticlePreviewResponse, ArticleSearchResultResponse, Collection } from "authory-api-types/dist/types"
import { ProfileView, Visibility } from "authory-api-types/dist/enums"
import { V3ProfileListItem, V3ProfileListItemMock } from "../V3ProfileListItem"
import { V3ProfileListContainer, V3ProfileListWrapper } from "./V3ProfileList.styles"
import { V3Loader } from "../Loader";
import { VerticalSpacing } from "../VerticalSpacing";
import { V3PinnedProfileItem } from "../V3PinnedProfileItem";
import { useWindowSize } from "../../hooks/useWindowSize";
import { getItemTarget, getItemUrl } from "./V3ProfileList.utils";
import { isMainDomain } from "../../utils/domainRouting";
import { RequestHost } from "../../types/headers";
import { DomainContext } from "../../context/domainContext";
import { ItemDropdownContainer } from "./ItemDropdownContainer";
import { MAX_PAGES_LOADED } from "../InfinitePaginationFooter";
import { NORMAL_RESULT_LIMIT } from "../../types/pagination";

const CHANGE_VIEW_TRESHOLD = 700;

interface V3ProfileListProps {
    items: ArticleSearchResultResponse[],
    viewType?: ProfileView,
    itemsLoading: boolean,
    pinnedItem?: ArticlePreviewResponse | null,
    itemsHref: string,
    isB2B?: boolean,
    collection?: Collection,
    collectionOrder?: string,
    isPrivateView?: boolean
    customDomainSearchTriggered?: boolean,
    editMode?: boolean,
    signupSkeleton?: boolean,
}

export const V3ProfileList = ({
    items,
    itemsLoading,
    viewType = ProfileView.TwoColumnThumbnail,
    pinnedItem,
    itemsHref,
    isB2B,
    collection,
    isPrivateView = false,
    customDomainSearchTriggered = false,
    editMode = false,
    signupSkeleton = false,
}: V3ProfileListProps) => {
    const [localView, setLocalView] = useState(viewType);
    const [readyToShow, setReadyToShow] = useState(true);
    const size = useWindowSize();

    const noItems = items.reduce((acc, curr) => acc + curr.articles.length, 0);

    const fewerItemsThanMaxPagination = noItems < MAX_PAGES_LOADED * NORMAL_RESULT_LIMIT;
    const moreItemsThanMaxPaginationAndIdle = (noItems > MAX_PAGES_LOADED * NORMAL_RESULT_LIMIT) && !itemsLoading;

    const showSkeletonItems = fewerItemsThanMaxPagination || moreItemsThanMaxPaginationAndIdle;

    // Current Domain
    const requestHostHeader: RequestHost = useContext(DomainContext) ?? RequestHost.MAINDOMAIN;

    useEffect(() => {
        const targetIsTwoColumn = viewType === ProfileView.TwoColumnThumbnail;
        const targetIsList = viewType === ProfileView.List;

        if (targetIsTwoColumn || targetIsList) {
            if (size.width && size.width < CHANGE_VIEW_TRESHOLD) {
                setLocalView(ProfileView.OneColumnThumbnail);
            } else if (size.width && size.width > CHANGE_VIEW_TRESHOLD) {
                if (targetIsTwoColumn) setLocalView(ProfileView.TwoColumnThumbnail);
                if (targetIsList) setLocalView(ProfileView.List);
            }
        } else {
            setLocalView(viewType);
        }

        if (size.width && !readyToShow) {
            setTimeout(() => {
                setReadyToShow(true);
            }, 30);
        }

    }, [size.width, viewType]);

    return <V3ProfileListContainer $visible={readyToShow}>
        {
            !customDomainSearchTriggered && pinnedItem && <VerticalSpacing bottom={60}>
                <ItemDropdownContainer item={{ ...pinnedItem, pinned: true }} editMode={editMode} collection={collection!}>
                    <V3PinnedProfileItem
                        item={pinnedItem}
                        viewType={localView}
                        href={getItemUrl(pinnedItem, itemsHref, collection?.canonicalSlug, isPrivateView, isMainDomain(requestHostHeader))}
                        target={pinnedItem.effectiveVisibility === Visibility.Preview && !isPrivateView ? "_blank" : undefined}
                        isB2B={isB2B}
                        isPrivateView={isPrivateView}
                    />
                </ItemDropdownContainer>
            </VerticalSpacing>
        }
        <V3ProfileListWrapper $viewType={localView}>
            {!customDomainSearchTriggered && items.map((group, i) => (
                <React.Fragment key={`${group}-${i}`}>
                    {group.articles.map((item, index2) => (
                        <React.Fragment key={`${item.canonicalSlug}-${index2}`}>
                            <div>
                                <ItemDropdownContainer item={item} editMode={editMode} collection={collection!} setIntercomTarget={i === 0 && index2 === 0}>
                                    <V3ProfileListItem
                                        item={item}
                                        viewType={localView}
                                        href={getItemUrl(item, itemsHref, collection?.canonicalSlug, isPrivateView, isMainDomain(requestHostHeader))}
                                        isB2B={isB2B}
                                        target={getItemTarget(item, isPrivateView)}
                                        isPrivateView={isPrivateView}
                                        isSignupPreview={signupSkeleton}
                                    />
                                </ItemDropdownContainer>
                            </div>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
            {
                showSkeletonItems && !itemsLoading && isPrivateView && Array.from(Array(1).keys()).map((index) => <V3ProfileListItemMock viewType={localView} key={index} trackOnScreen={index === 0} isProfileMock />)
            }
            {
                signupSkeleton && Array.from(Array(3).keys()).map((index) => <V3ProfileListItemMock viewType={localView} key={index} trackOnScreen={index === 0} isProfileMock={false} />)
            }
        </V3ProfileListWrapper>
        {itemsLoading && <VerticalSpacing top={32} bottom={32} style={{ minHeight: (items.length === 0 || customDomainSearchTriggered) ? 400 : undefined }}>
            <V3Loader />
        </VerticalSpacing>}
    </V3ProfileListContainer>
}