import { V3IconHelpCircle } from "../Icons";
import { V3Tooltip } from "../Tooltip";
import { NotificationInlineLink } from "./NotificationInlineLink";

interface DefaultCollectionTooltipProps {
    referenceElement?: JSX.Element,
    customOffset?: number,
}

export const DefaultCollectionTooltip = ({ referenceElement = <V3IconHelpCircle />, customOffset }: DefaultCollectionTooltipProps) => <V3Tooltip
    tooltipMaxWidth={250}
    customOffset={customOffset}
    referenceContent={referenceElement}
    tooltipContent={<>This collection is your default collection and contains everything that is in your Authory account. <NotificationInlineLink href="https://authory.com/help/en/articles/9847555-how-does-my-default-collection-work" target="_blank">Learn more</NotificationInlineLink></>}
/>