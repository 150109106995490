import Link from "next/link";
import { InlineLink } from "../ReusableStyledComponents/InlineLink"
import { APPLICATION_ROUTES } from "../../types/routes";
import { BannerDetails } from "../BannerDetails";

const getUpdateGrammarConnector = (hasFiltersEnabled: boolean, isManuallyOrdered: boolean) => {
    if (!hasFiltersEnabled) return "is";
    if (hasFiltersEnabled && !isManuallyOrdered) return "and is";
    if (hasFiltersEnabled && isManuallyOrdered) return ", it's";
    return "";
}

const getOrderGrammarConnector = (hasFiltersEnabled: boolean, isAutoUpdating: boolean) => {
    if (!hasFiltersEnabled && !isAutoUpdating) return "has been";
    return "and it's been";
}

interface CollectionBannerDetailsProps {
    isManuallyOrdered: boolean,
    isAutoUpdating: boolean,
    hasFiltersEnabled: boolean,
    onCollectionUpdateRules?: () => void,
    collectionSlug?: string,
}

export const CollectionBannerDetails = ({ isManuallyOrdered, isAutoUpdating, hasFiltersEnabled, onCollectionUpdateRules, collectionSlug }: CollectionBannerDetailsProps) => {

    if (!isManuallyOrdered && !hasFiltersEnabled) return <></>;

    return <BannerDetails>
        This collection {" "}
        {hasFiltersEnabled ? <><InlineLink onClick={() => { onCollectionUpdateRules && onCollectionUpdateRules() }}>has filters enabled</InlineLink></> : <></>}
        {isAutoUpdating ? <>{hasFiltersEnabled && isManuallyOrdered ? "" : " "}{getUpdateGrammarConnector(hasFiltersEnabled, isManuallyOrdered)} <InlineLink onClick={() => { onCollectionUpdateRules && onCollectionUpdateRules() }}> auto-updating</InlineLink></> : <></>}
        {isManuallyOrdered ? <> {getOrderGrammarConnector(hasFiltersEnabled, isAutoUpdating)} <Link href={`${APPLICATION_ROUTES.COLLECTION_SORT.replace("[collectionId]", collectionSlug || "")}`} legacyBehavior passHref><InlineLink> manually ordered</InlineLink></Link></> : <></>}
        .
    </BannerDetails>
}