import dynamic from "next/dynamic";
import { WebsiteResponse } from "authory-api-types/dist/types";
import { SIPTextWrapper, SIPWrapper } from "./SourceInProgress.styles";
import { V3HeadlineH5 } from "../Typography";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";

const V3SourceListItemPieChart = dynamic(
    () => import('../V3SourceListItem/V3SourceListItemPieChart'),
    { ssr: false }
);

interface SourceInProgressProps {
    sources: WebsiteResponse[],
}

export const SourceInProgress = ({ sources }: SourceInProgressProps) => {
    const earliestSrc = sources.sort((a, b) => new Date(b?.createdAt || "").getTime() - new Date(a?.createdAt || "").getTime())[0];

    const oneSourceWithContent = !!sources.find(src => src.articleCount > 0);

    return <SIPWrapper>
        <div>
            <V3SourceListItemPieChart
                createdAt={earliestSrc.createdAt}
            />
        </div>
        <div>
            <SIPTextWrapper>
                <div>
                    <V3HeadlineH5>{oneSourceWithContent ? "Your remaining content from" : "Your content from"} {sources.map(src => src.publicationName).join(", ")} is currently being imported.</V3HeadlineH5>
                    <V3CaptionGrey35>It will appear here in the next 48 hours and you will receive an email as soon as it does.</V3CaptionGrey35>
                </div>
            </SIPTextWrapper>
        </div>
    </SIPWrapper>
}