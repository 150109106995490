import { ProfileView } from "authory-api-types/dist/enums";
import styled from "styled-components";
import { media } from "../../theme/theme";

export const V3PinnedWrapper = styled.div<{ $viewType: ProfileView }>`
    background: ${({ theme }) => theme ? theme.v3.colors.grey96 : null};
    padding: ${({ $viewType }) => {
        switch ($viewType) {
            case ProfileView.TwoColumnThumbnail:
            case ProfileView.OneColumnThumbnail:
            case ProfileView.List:
                return "12px";
            case ProfileView.Text:
                return "18px";
        }
    }};
    border-radius: 6px;
    margin: 0 -20px;

    ${media.custom(700)} {
        margin: initial;
        padding: ${({ $viewType }) => {
        switch ($viewType) {
            case ProfileView.TwoColumnThumbnail:
            case ProfileView.OneColumnThumbnail:
            case ProfileView.List:
                return "12px";
            case ProfileView.Text:
                return "30px 30px 30px 50px";
        }
    }};
    }
`;