import { V3ProfileListItem, V3ProfileListItemProps } from "../V3ProfileListItem";
import { V3PinnedWrapper } from "./V3PinnedProfileItem.styles";

interface V3PinnedProfileItemProps extends V3ProfileListItemProps { }

export const V3PinnedProfileItem = ({
    item,
    viewType,
    href,
    isB2B,
    target,
    isPrivateView,
}: V3PinnedProfileItemProps) => <V3PinnedWrapper $viewType={viewType}>
        <V3ProfileListItem
            item={item}
            viewType={viewType}
            isPinned
            href={href}
            isB2B={isB2B}
            target={target}
            isPrivateView={isPrivateView}
        />
    </V3PinnedWrapper>